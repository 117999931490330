import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Table, Checkbox, Spinner, toast } from '@lionstep/ui';

import {
  useAddJobMailingPermissionsMutation,
  useAddUserToJobMutation,
  useSendUserNewJobEmailMutation,
} from '../../../../../../queries/jobQueries';
import { useUserCollaborators } from '../../../../../../queries/userQueries';

// Styles
import styles from './shareJobModal.module.scss';

const ShareJobModal = ({ onClose, jobs, lastAddedUserId, showModal }) => {
  const { t } = useTranslation();
  const [jobSelections, setJobSelections] = useState([]);

  const { data: collaborators } = useUserCollaborators({
    options: { enabled: false },
  });

  // Add Job Mailing Permission
  const { mutate: addJobMailingPermission, isLoading } =
    useAddJobMailingPermissionsMutation({
      onError: (error) => {
        toast.error('There was an error while adding job mailing permission.', {
          id: 'POST_ADD_JOB_MAILING_PERMISSION',
          statusCode: error?.response?.status,
        });
      },
    });

  // Add Job User
  const { mutate: addUserToJob } = useAddUserToJobMutation({
    onError: (error) => {
      toast.error('There was an error while adding job user.', {
        id: 'POST_ADD_JOB_USER',
        statusCode: error?.response?.status,
      });
    },
  });

  const { mutate: sendUserNewJobEmail } = useSendUserNewJobEmailMutation({});

  // Setting job locally
  const handleSetDefaultJobSelection = () => {
    setJobSelections(
      jobs.map((job) => ({
        ...job,
        notification: false,
        status: false,
      })),
    );
  };

  useEffect(() => {
    if (jobs?.length) {
      handleSetDefaultJobSelection();
    }
  }, []);

  const setStatusUpdate = (jobData) => {
    setJobSelections((currVal) =>
      currVal.map((job) => {
        if (job.job_id === jobData.job_id) {
          return {
            ...job,
            status: !job.status,
            notification: !job.status ? job.notification : false,
          };
        }

        return job;
      }),
    );
  };

  const setNotificationUpdate = (jobData) => {
    setJobSelections((currVal) =>
      currVal.map((job) => {
        if (job.job_id === jobData.job_id) {
          return {
            ...job,
            notification: !job.notification,
          };
        }

        return job;
      }),
    );
  };

  const handleCloseModal = () => {
    const filteredJobs = jobSelections.filter((job) => job.status);
    const userId = lastAddedUserId;
    const collaborator = collaborators.find(
      (collab) => collab.local_id === userId,
    );

    if (filteredJobs.length) {
      filteredJobs.forEach(async (job) => {
        try {
          await addUserToJob({ jobId: job.job_id, userId });

          if (job.notification) {
            await addJobMailingPermission({
              jobId: job.job_id,
              data: { user_id: userId },
            });
          }

          await sendUserNewJobEmail({
            user: collaborator,
            jobTitle: job.opening_title,
          });
        } catch (error) {
          console.log(error);
        }
      });
    }

    handleSetDefaultJobSelection();
    onClose();
  };

  return (
    <Modal className={styles.modal} show={showModal} onClose={onClose}>
      <Modal.Header>{t('share_jobs')}</Modal.Header>
      <Modal.Body className={styles.modalBody}>
        {isLoading && <Spinner position="fixed" size={100} />}
        <Table>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell colSpan={2}>
                {t('job_title')}!!!
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                {t('send_status_updates')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                {t('share_job')}
              </Table.HeaderCell>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {jobSelections.map((job, index) => (
              <Table.Row key={job.job_id}>
                <Table.Cell colSpan={2} data-testid={`job-title-${index}`}>
                  <span className="text-capitalize">{job.opening_title}</span>
                  {job.location_city && (
                    <>
                      <span className="text-lowercase"> {t('in')}</span>
                      <span className="text-capitalize">
                        {' '}
                        {job.location_city}
                      </span>
                    </>
                  )}
                </Table.Cell>
                <Table.Cell
                  textAlign="center"
                  data-testid={`notification-${index}`}
                >
                  <Checkbox
                    name="notification"
                    checked={job.notification}
                    disabled={!job.status}
                    onChange={() => setNotificationUpdate(job)}
                  />
                </Table.Cell>
                <Table.Cell textAlign="center" data-testid={`status-${index}`}>
                  <Checkbox
                    name="status"
                    checked={job.status}
                    onChange={() => setStatusUpdate(job)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer
        confirmText={t('submit')}
        closeText={t('cancel')}
        onConfirm={handleCloseModal}
        onClose={onClose}
      />
    </Modal>
  );
};

ShareJobModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  jobs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  lastAddedUserId: PropTypes.string.isRequired,
};

export default ShareJobModal;

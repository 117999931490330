import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import classnames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import {
  Button,
  FormCheckbox,
  FormEditor,
  FormInput,
  FormSelectV2,
  FormTextarea,
  Grid,
  Icon,
  Heading,
  Spinner,
  toast,
  FormSwitch,
} from '@lionstep/ui';

// Components
import JobVersion from '../../components/JobVersion/JobVersion';
import ContentCard from '../../../../components/refactoring/contentCard/ContentCard';
import PageHeading from '../../../../components/refactoring/pageHeading/PageHeading';
import AdminActionsInfoModal from './components/AdminActionsInfoModal/AdminActionsInfoModal';
import InfoPlusIconWithTooltip from '../../../../components/refactoring/infoPlusIconWithTooltip';
import KillerQuestions from './components/killerQuestions';
import OptionalQuestions from './components/optionalQuestions';
import Salary from './components/salary';

// Hooks
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useIsLionstepAdmin from '../../../../hooks/useIsLionstepAdmin';

// Styles
import styles from './adminActions.module.scss';

// Selectors
import {
  jobSelector,
  isLoadingJobSelector,
  extraInfoSelector,
  isLoadingExtraInfoSelector,
} from '../../redux/selectors/job.selectors';
import {
  accountManagersSelector,
  citiesSelector,
  isLoadingCitiesSelector,
  isLoadingAccountManagerSelector,
} from '../../redux/selectors/adminActions.selectors';

// Actions
import {
  getAccountManagers,
  getCities,
  clearCities,
  updateJobAdminInfo,
} from '../../redux/actions';

// Helpers
import {
  adminActionsFormMapperInput,
  adminActionsFormMapperOutput,
} from './adminActions.helper';
import { copyTextToClipboard } from '../../../../support';
import { jobMapperInput } from '../../components/JobForm/job.helper';

// Constants
import {
  COMPLEXITY_LEVEL_OPTIONS,
  JOB_INDUSTRIES_ACUITY_OPTIONS,
  JOB_PRODUCT_OPTIONS,
  JOB_PRODUCT_OPTIONS_OLD,
  JOB_STATUSES_OPTIONS,
  JOB_STATUSES,
  PERSONALITY_TEST_TRIGGER_OPTIONS,
  RECOMMENDATION_REGION_OPTIONS,
  VERSION_SUBMIT_TYPES,
} from '../../job.constants';

// Config
import { JOBS_PORTAL_APP } from '../../../../config';

// Queries
import { useQuestionsQuery } from '../../../../queries/jobQueries';

// Schema
import { publishJobSchema } from '../../components/JobForm/job.schema';

const cx = classnames.bind(styles);

const schema = Yup.object().shape({
  account_manager: Yup.object().shape().nullable(),
  caller_info: Yup.string().nullable(),
  competitors_list: Yup.string().nullable(),
  preferred_companies: Yup.string().nullable(),
  complexity: Yup.object().shape().nullable(),
  ads_only: Yup.bool().nullable(),
  job_industry: Yup.object().shape().nullable(),
  personality_check: Yup.bool().nullable(),
  personality_check_trigger: Yup.object().shape().nullable(),
  publish_to_job_board: Yup.bool().nullable(),
  urgernt: Yup.bool().nullable(),
  is_one_landing_page: Yup.bool().nullable(),
  search_exclude_cities: Yup.array(Yup.object().shape()).nullable(),
  search_locations: Yup.object().shape().required('Required'),
  searcher_info: Yup.string().nullable(),
  status: Yup.object().shape().nullable(),
  job_product: Yup.object().shape().nullable(),
  number_of_positions: Yup.number().min(1).required('Required'),
  free_question: Yup.string().nullable(),
  questionnaire: Yup.string().nullable(),
  // NAS Info
  multichannel: Yup.array().of(
    Yup.object().shape({
      location: Yup.object().shape().required('Required').nullable(),
      title: Yup.string().required('Required'),
    }),
  ),
  optional_questions_enabled: Yup.bool().nullable(),
  // Salary
  base_salary_min: Yup.string()
    .required('Required')
    .test(
      'min_salary',
      'Must be minimum 10000',
      (value) => Number(value) >= 10000,
    )
    .matches(/^\d*$/g, 'Must contain only numbers')
    .when('base_salary_max', (baseSalaryMax, sch) =>
      sch.test({
        test: (baseSalaryMin) => {
          if (baseSalaryMax && baseSalaryMin) {
            if (parseInt(baseSalaryMax, 10) < 10000) return true;

            return !(parseInt(baseSalaryMin, 10) > parseInt(baseSalaryMax, 10));
          }
          return true;
        },
        message: 'Must be less than maximum salary',
      }),
    ),
  base_salary_max: Yup.string()
    .nullable()
    .matches(/^\d*$/g, 'Must contain only numbers')
    .test('max_salary', 'Must be minimum 10000', (value) => {
      if (!value) return true;

      return Number(value) >= 10000;
    }),
});

const AdminActions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const isLionstepAdmin = useIsLionstepAdmin();
  const versionRef = useRef();
  const killerQuestionsRef = useRef();
  const optionalQuestionsRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [modalMessageType, setModalMessageType] = useState([]);
  const [submitType, setSubmitType] = useState(VERSION_SUBMIT_TYPES.update);
  const [nasLocationFocused, setNasLocationFocused] = useState('');

  const job = useSelector(jobSelector);
  const extraInfo = useSelector(extraInfoSelector);
  const isLoading = useSelector(isLoadingJobSelector);
  const isLoadingExtraInfo = useSelector(isLoadingExtraInfoSelector);
  const isLoadingCities = useSelector(isLoadingCitiesSelector);
  const CITIES_OPTIONS = useSelector(citiesSelector);
  const ACCOUNT_MANAGERS_OPTIONS = useSelector(accountManagersSelector);
  const isLoadingAccountManagers = useSelector(isLoadingAccountManagerSelector);

  const { isFetching: isLoadingOptionalQuestions } = useQuestionsQuery({
    id: params.id,
    questionType: 'optional_questions',
  });

  const { activeBreakpoints } = useMediaQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLionstepAdmin) {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    dispatch(getAccountManagers());
  }, []);

  const formMethods = useForm({
    defaultValues: adminActionsFormMapperInput(
      job,
      extraInfo,
      ACCOUNT_MANAGERS_OPTIONS,
    ),
    resolver: yupResolver(schema),
  });

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;

  const {
    fields: nasInfoFields,
    append: appendNasInfo,
    remove: removeNasInfo,
  } = useFieldArray({
    control,
    name: 'multichannel',
  });

  useEffect(() => {
    if (
      ACCOUNT_MANAGERS_OPTIONS.length &&
      job.account_manager &&
      !getValues('account_manager')
    ) {
      reset(
        adminActionsFormMapperInput(job, extraInfo, ACCOUNT_MANAGERS_OPTIONS),
      );
    }
  }, [ACCOUNT_MANAGERS_OPTIONS]);

  const watchPersonalityCheck = watch('personality_check');
  const watchPublishToMultichannel = watch('publish_to_job_board');
  const watchOptionalQuestionsEnabled = watch('optional_questions_enabled');

  // Remove incomplete nas values if publish to job boards was turned off
  useEffect(() => {
    if (watchPublishToMultichannel) {
      setValue(
        'multichannel',
        job.multichannel?.map((nasInfoRecord) => ({
          ...nasInfoRecord,
          location: {
            label: nasInfoRecord.location.name,
            value: nasInfoRecord.location,
          },
        })) ?? [],
      );
    } else {
      const completeValues = getValues('multichannel').filter(
        ({ location, title }) => location && title,
      );

      setValue('multichannel', completeValues);
    }
  }, [watchPublishToMultichannel]);

  const onCitiesSearch = debounce((value) => {
    if (
      value &&
      value.length > 2 &&
      !CITIES_OPTIONS.some((city) => city.label === value)
    ) {
      dispatch(getCities(value));
    }
  }, 500);

  const onCitiesBlur = () => {
    setTimeout(() => {
      dispatch(clearCities());
    }, 150);
  };

  const handleOnNasInfoAdd = () => {
    let shouldAddEmptyRow = true;
    const currentValues = getValues('multichannel');

    if (currentValues.length) {
      currentValues.forEach((nasInfoRow) => {
        if (!nasInfoRow.location || !nasInfoRow.title) {
          shouldAddEmptyRow = false;
        }
      });
    }

    if (shouldAddEmptyRow) {
      appendNasInfo({
        location: undefined,
        title: undefined,
      });
    }
  };

  const onSubmit = async (data, type) => {
    // Killer questions
    const killerQuestionsErrors =
      await killerQuestionsRef.current.onKillerQuestionsValidation(true);

    if (killerQuestionsErrors.length) {
      return;
    }

    // Optional questions
    const isOptionalQuestionsValid =
      await optionalQuestionsRef.current.onOptionalQuestionsValidation(true);

    if (!isOptionalQuestionsValid) {
      return;
    }

    // Rest
    let hasWarning = false;

    if (
      data.status.value === JOB_STATUSES.jobClosed &&
      job.status !== JOB_STATUSES.jobClosed
    ) {
      setModalMessageType((prevState) => [...prevState, 'jobClosed']);
      hasWarning = true;
    }

    if (
      job.status === null &&
      data.status.value &&
      data.status.value !== JOB_STATUSES.jobClosed
    ) {
      setModalMessageType((prevState) => [...prevState, 'jobPublished']);
      hasWarning = true;
    }

    if (data.personality_check && !job.personality_check) {
      setModalMessageType((prevState) => [...prevState, 'personalityEnabled']);
      hasWarning = true;
    }

    if (hasWarning) {
      setShowModal(true);
    } else {
      dispatch(updateJobAdminInfo(adminActionsFormMapperOutput(data), job));
      killerQuestionsRef.current.onKillerQuestionsSubmit();
      optionalQuestionsRef.current.onOptionalQuestionsSubmit();

      if (type === VERSION_SUBMIT_TYPES.create) {
        versionRef.current.createVersion();
      } else {
        versionRef.current.updateVersion();
      }
    }
  };

  const onModalConfirmJobValidation = async (adminActionsData) => {
    try {
      if (adminActionsData.status.value !== JOB_STATUSES.jobDescriptionDone) {
        return true;
      }

      const jobData = {
        ...job,
        ...extraInfo,
        benefits_checked: [
          ...(job?.benefits_pool_selected ?? []),
          ...(job?.benefits_custom_selected ?? []),
        ],
      };

      await publishJobSchema.validate(jobMapperInput(jobData, t), {
        abortEarly: false,
      });

      return true;
    } catch (error) {
      toast.info(t('admin_actions_page.job_validation_message'), {
        id: 'ADMIN_ACTION_PUBLISH_JOB_FAIL',
      });

      return false;
    }
  };

  const onModalClose = () => {
    setShowModal(false);
    setModalMessageType([]);
  };

  const onModalConfirm = async () => {
    const data = getValues();
    const isJobValid = await onModalConfirmJobValidation(data);

    if (isJobValid) {
      dispatch(updateJobAdminInfo(adminActionsFormMapperOutput(data), job));
      if (submitType === VERSION_SUBMIT_TYPES.create) {
        versionRef.current.createVersion();
      } else {
        versionRef.current.updateVersion();
      }
    }

    onModalClose();
  };

  // submit admin actions and update version
  const handleOnAdminActionsSave = () => {
    setSubmitType(VERSION_SUBMIT_TYPES.update);
    handleSubmit(
      (data) => onSubmit(data, VERSION_SUBMIT_TYPES.update),
      () => killerQuestionsRef.current.onKillerQuestionsValidation(),
    )();
  };

  // submit admin actions and create version
  const handleOnVersionCreate = () => {
    setSubmitType(VERSION_SUBMIT_TYPES.create);
    handleSubmit(
      (data) => onSubmit(data, VERSION_SUBMIT_TYPES.create),
      () => killerQuestionsRef.current.onKillerQuestionsValidation(),
    )();
  };

  const accountManagersOptions = useMemo(
    () => sortBy(ACCOUNT_MANAGERS_OPTIONS, ['label']),
    [ACCOUNT_MANAGERS_OPTIONS],
  );

  if (isLoadingAccountManagers || !ACCOUNT_MANAGERS_OPTIONS.length)
    return <Spinner position="fixed" size={100} />;

  const JOB_PRODUCT_OPTIONS_FORMATTED = [
    ...JOB_PRODUCT_OPTIONS,
    ...(job.job_product
      ? JOB_PRODUCT_OPTIONS_OLD.filter(
          (option) => option.value === job.job_product,
        )
      : []),
  ];

  return (
    <>
      <Helmet>
        <title>{t('admin_actions_page.title')} | Lionstep</title>
      </Helmet>

      {(isLoading || isLoadingExtraInfo || isLoadingOptionalQuestions) && (
        <Spinner position="fixed" size={100} />
      )}

      <AdminActionsInfoModal
        actionType={modalMessageType}
        show={showModal}
        onClose={onModalClose}
        onConfirm={onModalConfirm}
      />

      <FormProvider {...formMethods}>
        <Grid.Container className={cx(styles.adminActions)}>
          <Grid.Row>
            <Grid.Col xs={24}>
              <PageHeading additionalInfo={job?.opening_title}>
                {t('admin_actions_page.title')}
                <InfoPlusIconWithTooltip />
              </PageHeading>
            </Grid.Col>
          </Grid.Row>

          <ContentCard className={styles.jobSettingsCard} id="jobSettings">
            <Grid.Row gutter={30}>
              <Grid.Col xs={24}>
                <Heading className={styles.contentCardTitle} level={3}>
                  {t('job_settings')}
                </Heading>
              </Grid.Col>
              <Grid.Col xs={24} md={12} lg={8}>
                <FormSelectV2
                  label="Job status"
                  name="status"
                  options={JOB_STATUSES_OPTIONS}
                />
              </Grid.Col>
              <Grid.Col xs={24} md={12} lg={8}>
                <FormSelectV2
                  searchableWithOpenOnFocus
                  label="Account Manager"
                  name="account_manager"
                  options={accountManagersOptions}
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row gutter={30}>
              <Grid.Col xs={24} sm={12} lg={8}>
                <FormCheckbox
                  label="Personality Test"
                  name="personality_check"
                  options={[{ label: 'Active' }]}
                />
              </Grid.Col>

              <Grid.Col xs={24} sm={12} lg={8}>
                <FormCheckbox
                  label="Ads Only"
                  name="ads_only"
                  options={[{ label: 'Active' }]}
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row gutter={30}>
              <Grid.Col xs={24} md={12} lg={8}>
                <FormSelectV2
                  label="Complexity Level"
                  name="complexity"
                  options={COMPLEXITY_LEVEL_OPTIONS}
                />
              </Grid.Col>

              <Grid.Col xs={24} md={12} lg={8}>
                <FormSelectV2
                  label="When should we invite the candidate"
                  name="personality_check_trigger"
                  disabled={!watchPersonalityCheck}
                  options={PERSONALITY_TEST_TRIGGER_OPTIONS}
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row gutter={30}>
              <Grid.Col xs={24} sm={12} lg={8}>
                <FormCheckbox
                  label="Publish to multichannel"
                  name="publish_to_job_board"
                  hint={t('company_page.general_section.publish_job.helptext')}
                  options={[{ label: 'Yes' }]}
                />
              </Grid.Col>
              <Grid.Col xs={24} sm={12} lg={8}>
                <FormCheckbox
                  label="Urgent"
                  name="urgent"
                  options={[{ label: 'Yes' }]}
                />
              </Grid.Col>
              <Grid.Col xs={24} sm={12} lg={8}>
                <FormCheckbox
                  label="One Landing Page"
                  name="is_one_landing_page"
                  options={[{ label: 'Yes' }]}
                />
              </Grid.Col>
            </Grid.Row>

            {/* ADDITIONAL NAS LOCATIONS & TITLES */}
            {watchPublishToMultichannel && (
              <div
                className={cx({
                  [styles.nasInfoContainer]: nasInfoFields.length,
                })}
              >
                {nasInfoFields.map((field, index) => (
                  <Grid.Row
                    key={field.id}
                    gutter={{
                      xs: 15,
                      md: 0,
                    }}
                  >
                    <Grid.Col xs={21} sm={22} md={16}>
                      <Grid.Row gutter={30}>
                        <Grid.Col xs={24} md={12}>
                          <FormSelectV2
                            searchable
                            required
                            name={`multichannel.${index}.location`}
                            label="NAS Location"
                            placeholder="Add location"
                            error={
                              errors.multichannel?.[index]?.location?.message
                            }
                            onSearch={onCitiesSearch}
                            options={CITIES_OPTIONS}
                            isLoading={
                              nasLocationFocused === index && isLoadingCities
                            }
                            onFocus={() => setNasLocationFocused(index)}
                          />
                        </Grid.Col>

                        <Grid.Col xs={24} md={12}>
                          <Grid.Row>
                            <Grid.Col xs={24}>
                              <FormInput
                                required
                                label="NAS Title"
                                placeholder="Add title"
                                name={`multichannel.${index}.title`}
                                error={
                                  errors.multichannel?.[index]?.title?.message
                                }
                                prefix={<Icon name="Search" size={22} />}
                              />
                            </Grid.Col>
                          </Grid.Row>
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>

                    <Grid.Col
                      xs={3}
                      sm={2}
                      className={styles.removeNasInfoSection}
                    >
                      <div className={styles.removeNasInfoLine} />
                      <button
                        type="button"
                        className={styles.removeNasInfoButton}
                        onClick={() => removeNasInfo(index)}
                      >
                        <Icon
                          name="CloseCircle"
                          className={styles.removeNasInfoIcon}
                          size={activeBreakpoints.includes('md') ? 22 : 33}
                        />
                      </button>
                    </Grid.Col>
                  </Grid.Row>
                ))}

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Button
                      className={styles.addNasInfoButton}
                      typeHtml="button"
                      onClick={handleOnNasInfoAdd}
                      icon={<Icon name="Plus" />}
                      ghost
                      data-testid="add-nas-info-btn"
                    >
                      Add additional NAS Title
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </div>
            )}

            <Grid.Row gutter={30}>
              <Grid.Col xs={24} md={12} lg={8}>
                <FormSelectV2
                  label="Product of a Job"
                  placeholder="Select"
                  name="job_product"
                  error={errors.job_product?.message}
                  options={JOB_PRODUCT_OPTIONS_FORMATTED}
                />
              </Grid.Col>

              <Grid.Col xs={24} md={12} lg={8}>
                <FormInput
                  label="Number of positions to be filled"
                  name="number_of_positions"
                  required
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row gutter={30}>
              <Grid.Col xs={24}>
                <FormInput
                  label="Public URL"
                  name="public_url"
                  readOnly
                  value={`${JOBS_PORTAL_APP}/job/${job?.public_url}`}
                  actionAddon={
                    <Button
                      minWidth={false}
                      onClick={() =>
                        copyTextToClipboard(
                          `${JOBS_PORTAL_APP}/job/${job?.public_url}`,
                        )
                      }
                      icon={
                        !activeBreakpoints.includes('lg') ? (
                          <Icon name="Copy" />
                        ) : null
                      }
                    >
                      {activeBreakpoints.includes('lg') && 'Copy'}
                    </Button>
                  }
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row gutter={30}>
              <Grid.Col xs={24} md={12} lg={8}>
                <FormSelectV2
                  label="Job Industry"
                  name="job_industry"
                  options={JOB_INDUSTRIES_ACUITY_OPTIONS}
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row gutter={30}>
              <Grid.Col xs={24} md={12} lg={8}>
                <FormSelectV2
                  label="Search Region Recommendation"
                  name="search_locations"
                  required
                  error={errors.search_locations?.message}
                  options={RECOMMENDATION_REGION_OPTIONS}
                />
              </Grid.Col>

              <Grid.Col xs={24} md={16}>
                <FormSelectV2
                  multiple
                  searchable
                  isLoading={isLoadingCities}
                  label="Cities to exclude recommendation"
                  name="search_exclude_cities"
                  onBlur={onCitiesBlur}
                  onSearch={onCitiesSearch}
                  options={CITIES_OPTIONS}
                  placeholder="City To Exclude"
                  showTags
                  tagsPosition="top"
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col xs={24}>
                <FormEditor
                  label={t(
                    'job_description_page.meta_section.questionnaire.label',
                  )}
                  name="questionnaire"
                  error={errors.questionnaire?.message}
                  max={10000}
                  collapsable
                />
              </Grid.Col>

              <Grid.Col xs={24}>
                <FormEditor
                  label="Searcher Info"
                  name="searcher_info"
                  max={Infinity}
                  collapsable
                />
              </Grid.Col>

              <Grid.Col xs={24}>
                <FormTextarea
                  label="No-Hire Competitors"
                  name="competitors_list"
                  resize
                  collapsable
                />
              </Grid.Col>

              <Grid.Col xs={24}>
                <FormTextarea
                  label="Preferred companies"
                  name="preferred_companies"
                  resize
                  collapsable
                />
              </Grid.Col>

              {/* <Grid.Col xs={24}>
                <FormEditor
                  label="Caller Info"
                  name="caller_info"
                  max={Infinity}
                  collapsable
                />
              </Grid.Col> */}

              <Grid.Col xs={24}>
                <FormEditor
                  label={t(
                    'job_description_page.meta_section.custom_question.label',
                  )}
                  placeholder={t(
                    'job_description_page.meta_section.custom_question.placeholder',
                  )}
                  name="free_question"
                  error={errors.free_question?.message}
                  max={10000}
                  collapsable
                />
              </Grid.Col>
            </Grid.Row>
          </ContentCard>

          <ContentCard id="salary">
            <Grid.Col xs={24}>
              <Heading className={styles.contentCardTitle} level={3}>
                {t('salary')}
              </Heading>
            </Grid.Col>
            <Salary />
          </ContentCard>

          <ContentCard id="killerQuestions">
            <Grid.Col xs={24}>
              <Heading className={styles.contentCardTitle} level={3}>
                {t('killer_questions.title')}
              </Heading>
            </Grid.Col>
            <KillerQuestions ref={killerQuestionsRef} />
          </ContentCard>

          <ContentCard id="optionalQuestions">
            <Grid.Col xs={24}>
              <Heading className={styles.contentCardTitle} level={3}>
                {t('optional_questions.title')}
                <FormSwitch
                  name="optional_questions_enabled"
                  spacing={{ margin: 0, padding: 0 }}
                  formItemClassName={styles.optionalQuestionsSwitch}
                />
              </Heading>
            </Grid.Col>
            <OptionalQuestions
              ref={optionalQuestionsRef}
              isOptionalQuestionsEnabled={watchOptionalQuestionsEnabled}
              disableQuestions={() =>
                setValue('optional_questions_enabled', false)
              }
            />
          </ContentCard>

          {job && <JobVersion ref={versionRef} jobId={job.id} />}

          <Grid.Row gutter={[16, 16]}>
            <Grid.Col xs={24} sm={12} md="auto">
              <Button
                type="primary"
                block
                className={styles.actionButtons}
                onClick={handleOnAdminActionsSave}
              >
                Submit
              </Button>
            </Grid.Col>
            <Grid.Col xs={24} sm={12} md="auto">
              {job?.status && (
                <Button
                  type="primary"
                  block
                  className={styles.actionButtons}
                  onClick={handleOnVersionCreate}
                  ghost
                >
                  {t('job.versions.current.create_new_btn')}
                </Button>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </FormProvider>
    </>
  );
};

export default AdminActions;
